var RegisterBox = function($element, options){
    var ret = false;

    this.element = $element;

    this.defaults = {
        ticket_types: [],
        adultPrice: 0,
        childPrice: 0
    };
    this.options = jQuery.extend({}, this.defaults, options);
    
    this.priceField = this.element.find('[data-role="price"]');

    this.currentTab = undefined;

    ret = this.init();

    return true;
};

RegisterBox.prototype = {
    defaults: {},

    init: function ()
    {
        var _self = this;

        this.setTab('tab1');
        this.initEvents();
    },

    setTab: function(tab) {
        var _self = this;

        if (tab == this.currentTab) return;

        if (!_self.validate(tab)) {
            return;
        }

        var tabs = this.element.find('.form-tab[data-index!="' + tab + '"]');
        tabs.removeClass('shown');
        setTimeout(function() {
            tabs.addClass('hidden');

            if (tab == 'tab1') {
                _self.element.find('.buy-button').addClass('hidden');
                _self.element.find('.back-button').addClass('hidden');

                _self.element.find('.next-button').removeClass('hidden');
            }
            else {

                _self.element.find('.next-button').addClass('hidden');
                
                _self.element.find('.back-button').removeClass('hidden');
                _self.element.find('.buy-button').removeClass('hidden');

                _self.setSummaries();
                _self.element.find('.time-value').text(_self.getValue(_self.element.find('input[name="reg-time"]:checked').val()) );
            }

            _self.element.find('.form-tab[data-index="' + tab + '"]').removeClass('hidden').addClass('shown');

        }, 300);
    },

    initEvents: function() {
        var _self = this;

        var button = this.element.find('.next-button');
        button.off('click');
        this.element.on('click', '.next-button', function() {
            _self.setTab('tab2');
        });

        var button = this.element.find('.back-button');
        button.off('click');
        this.element.on('click', '.back-button', function() {
            _self.setTab('tab1');
        });

        var button = this.element.find('.register-submit');
        button.off('click');
        this.element.on('click', '.register-submit', function() {
            var form = $(this).closest('form');
            validateForm(form, ajax_controller + 'register', function(ret) {
                if (ret.success) {
                    if (typeof window.fbq === 'function') {
                        fbq('track', 'InitiateCheckout', {
                            value: ret.price,
                            currency: ret.currency
                        })
                    }
                }
            });
        });

        var nameField = this.element.find('input[name="reg-name"]');
        nameField.off('change');
        this.element.on('change', 'input[name="reg-name"]', function() {
            var val = $(this).val();
            var billing = _self.element.find('input[name="billing-name"]');
            billing.val(val);
            if (val) {
                billing.parent().addClass('is-dirty');
                billing.parent().removeClass('is-invalid');
            }
            else {
                billing.parent().removeClass('is-dirty');
            }
        });

        // var countryField = this.element.find('input[name="billing-country-label"]');
        _self.element.on('click', '.mdl-menu__item', function() {
            var val = $(this).data('value');
            var countryLabel = _self.element.find('input[name="billing-country-label"]').parent();
            if (val) {
                countryLabel.addClass('is-dirty');
            }
            else {
                countryLabel.removeClass('is-dirty');
            }

            var countrySelect = _self.element.find('select[name="billing-country"]');
            countrySelect.val(val);
        });

        var invalid = this.element.find('.is-invalid, .is-invalid-row');
        invalid.off('click');
        this.element.on('click', '.is-invalid, .is-invalid-row', function() {
            $(this).removeClass('is-invalid is-invalid-row');
            $(this).next('.error-text').fadeOut(300);
            $(this).find('.error-text').fadeOut(300);
        });
    },

    getPrice: function() {
        var _self = this;
        var price = 0;

        this.options.ticket_types.forEach(function(typ) {
            var name = typ.key
            var adult = parseInt(_self.element.find('.'+name+'-row [data-role="adult"]').val()) || 0
            var child = parseInt(_self.element.find('.'+name+'-row [data-role="child"]').val()) || 0

            if (typ.adult_price) {
                price += typ.adult_price * adult
            }

            if (typ.child_price) {
                price += typ.child_price * child
            }
        });

        return price;
    },

    setSummaries: function() {
        var _self = this;

        this.element.find('.summary [data-ticket-type]').each(function(ind, el) {
            var ticketType = $(el).attr('data-ticket-type');
            var hasValue = false;

            $(el).find('[data-input]').each(function(ind, el) {
                var th = $(el);
                var name = th.attr('data-input');
                var input = _self.element.find('input[name="' + name + '"]');
                if (!input.length) {
                    hasValue = false;
                    th.hide();
                    return
                }
                var value = input.val();

                if (input.attr('data-type') && input.attr('data-type') == 'integer') {
                    value = Number(value);
                    if (isNaN(value)) {
                        value = 0;
                    }
                }
                else {
                    value = _self.getValue(value);
                }

                if (value != 0) {
                    hasValue = true;
                    th.parent().show();
                } else {
                    th.parent().hide();
                }

                th.text(value);
            });

            if (!hasValue) {
                $(el).hide();
            } else {
                $(el).show();
            }
        });

        // Set billing
        this.element.find('.summary [data-input]').each(function(ind, el) {
            var th = $(el);
            var name = th.attr('data-input');
            var input = _self.element.find('input[name="' + name + '"]');
            var value = input.val();

            if (name == 'billing') {
                var country = _self.element.find('input[name="billing-country-label"]').val();
                var city = _self.element.find('input[name="billing-city"]').val();
                var address = _self.element.find('input[name="billing-address"]').val();
                var name = _self.element.find('input[name="billing-name"]').val();
                var zip = _self.element.find('input[name="billing-zip"]').val();

                value = name && zip && city && address && country ? (name + ', ' + zip + ' ' + city + ', ' + address + ', ' + country) : '';
            }

            value = _self.getValue(value);

            th.text(value);
        });

        this.priceField.text(this.getPrice());
    },

    getValue: function(value) {
        return value ? value : this.options.texts.empty;
    },

    validate: function(tab) {

        var firstError = undefined;

        if (tab == 'tab2') {

            this.element.find('.is-invalid').removeClass('is-invalid');
            this.element.find('.is-invalid-row').removeClass('is-invalid-row');
            this.element.find('.error-text').remove();

            var empties = {};
            var errors = {};
            var checkers = {};

            this.element.find('.form-tab[data-index="tab1"] input').each(function(ind, el) {

                var th = $(el);
                var isReq = th.data('required');
                var type = th.data('type') || 'string';
                var name = th.attr('name');
                var inputType = th.attr('type');
                var val = th.val();

                if (isReq) {
                    if ((inputType == 'radio' || inputType == 'checkbox')) {
                        if (!checkers[name]) {
                            checkers[name] = 0;
                        }
                        if (th.prop('checked')) {
                            checkers[name]++;
                        }
                    }
                    else if (!val) {
                        empties[name] = name;
                    }
                }
 
                switch (type) {
                    case 'integer':
                        if (isNaN(Number(th.val()))) {
                            errors[name] = name;
                        } else if(parseInt(th.val()) < 0) {
                            errors[name] = name;
                        }
                    break;
                    case 'phone':
                        var regex = /^[0-9 +-]+$/;
                        if (!regex.test(th.val())) {
                            if (!empties[name]) {
                                errors[name] = name;
                            }
                        }
                    break;
                    case 'email':
                        var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                        if (!regex.test(th.val())) {
                            if (!empties[name]) {
                                errors[name] = name;
                            }
                        }
                    break;

                }

            });

            for (var i in checkers) {
                if (!checkers[i]) {
                    empties[i] = i;
                }
            }

            for (var i in errors) {
                if (!firstError) {
                    firstError = errors[i];
                }
                var parent = $('[name="' + errors[i] + '"]').parent();
                parent.addClass('is-invalid');

                if (parent.data('invalid-text') != undefined) {
                    parent.after($('<span/>', {
                        'html' : parent.data('invalid-text'),
                        'class' : 'error-text'
                    }));
                }
            }

            for (var i in empties) {
                if (!firstError) {
                    firstError = empties[i];
                }
                var parent = $('[name="' + empties[i] + '"]').parent();
                parent.addClass('is-invalid');

                if($.inArray($('[name="' + empties[i] + '"]').attr('type'), ['radio', 'checkbox']) !== -1) {
                    var $parent = $('[name="' + empties[i] + '"]').closest('.form-row');
                    $parent.addClass('is-invalid-row');

                    $parent.find('.form-row-title').append($('<span/>', {
                        'html' : $parent.data('invalid-text'),
                        'class' : 'error-text'
                    }));
                }
            }

            if (firstError) {
                this.element.closest('.jqmodal').animate({scrollTop: $('[name="' + firstError + '"]').closest('.form-row').scrollTop() }, 500);
            }

        }

        return firstError ? false : true;
    }
};
