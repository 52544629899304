var EqualHeights = function($element, options){
    this.element = $element;

    this.defaults = {
        contentSelector: '.dynamic-content',
        resizeThreshold: 450
    };
    this.options = jQuery.extend({}, this.defaults, options);

    this.init();
};

EqualHeights.prototype = {
    defaults: {},

    init: function ()
    {
        var _self = this;

        $(window).resize(function() {
            _self.update();

        });

        _self.update();
    },

    update: function() {
        var _self = this;

        _self.element.find(_self.options.contentSelector).css('height', '');
        
        if ($(window).width() <= _self.options.resizeThreshold) {
            return;
        }

        var maxh = 0;
        _self.element.find(_self.options.contentSelector).each(function(ind, el) {
            var h = $(el).height();
            if (h > maxh) {
                maxh = h;
            }
        }).css('height', maxh);
        // console.log(_self.options.contentSelector + ' updated');
    }
};