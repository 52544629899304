var mapboxPolygons = {
    "type": "FeatureCollection",
    "properties": {
        "styles": {
            "polygon": {
                "fill-color": "#CEB874",
                "fill-opacity": 0.15
            },
            "linestring": {
                "line-color": "#EC3535",
                "line-width": 4,
                "line-dasharray": [-.8, 1.8]
            }
        }
    },
    "features": [
        {
            "customProps": {
                "id": "polygon1",
                "layerType": "fill",
            },
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [

                        [21.279481648284218, 48.193113975429725],
                        [21.280129407539363, 48.193047542983294],
                        [21.280784640955517, 48.192914677840804],
                        [21.280515571714318, 48.192509437002485],
                        [21.280263735458362, 48.192185436589256],
                        [21.280166571550325, 48.19205755173735],
                        [21.28007688180324, 48.19198281369154],
                        [21.279954804113117, 48.19191804062504],
                        [21.27984518331411, 48.19186821513759],
                        [21.27955369164269, 48.19180510278514],
                        [21.27871658735424, 48.19177520743088],
                        [21.278684199384912, 48.191768564017394],
                        [21.278813751235873, 48.19214225472771],
                        [21.279077837709565, 48.192525354214894],
                        [21.279227320612534, 48.19273295743645],
                        [21.279339432789783, 48.19289405696213],
                        [21.279461510506223, 48.193070103282935]
                    ]
                ]
            },
        },
        {
            "customProps": {
                "id": "polygon2",
                "layerType": "fill",
            },
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [21.28088899830422, 48.19298343697392],
                        [21.28092636902997, 48.192921986808926],
                        [21.28100360185971, 48.192867179842125],
                        [21.281494404077222, 48.192706080240725],
                        [21.28156914552875, 48.19266455964018],
                        [21.28170118875792, 48.19257321420406],
                        [21.281790878505035, 48.19253833681037],
                        [21.28188056825212, 48.19240380950811],
                        [21.281728593882832, 48.19193379176181],
                        [21.281623955866536, 48.1917444547048],
                        [21.281265197063675, 48.191274430999414],
                        [21.281113222609832, 48.19038585839078],
                        [21.28104844668789, 48.19027457819976],
                        [21.280976196614574, 48.190224751114386],
                        [21.280941317267065, 48.19020980297407],
                        [21.28084664476353, 48.19019817664258],
                        [21.280243730368795, 48.19021312478628],
                        [21.280159023378047, 48.19023969924157],
                        [21.280116669895875, 48.19026295188303],
                        [21.280041928444433, 48.190407450196545],
                        [21.2800344543098, 48.19046392068779],
                        [21.279807738381436, 48.19094231888184],
                        [21.279787807329484, 48.19101373669386],
                        [21.279775350617882, 48.19133960462463],
                        [21.279987118195493, 48.19189100976442],
                        [21.28011916146596, 48.19196076542093],
                        [21.280218816725892, 48.19204712941354],
                        [21.280438058489608, 48.19233279395263],
                        [21.280570101745155, 48.192495555564136]
                    ]
                ]
            },
        },
        {
            "customProps": {
                "id": "line1",
                "layerType": "line"
            },
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [21.28081002303847, 48.19293171930488],
                    [21.280543082875226, 48.192509089101605],
                    [21.28021608118584, 48.192064211424736],
                    [21.280122652135788, 48.19198858184103],
                    [21.279929120513913, 48.19188625927822],
                    [21.279742262413777, 48.19136574569109],
                    [21.279762282922462, 48.19099648925689],
                    [21.280002529069407, 48.190475966631794],
                    [21.280002529069407, 48.19041813045399],
                    [21.280075937610718, 48.19026241736131],
                    [21.280102631627045, 48.19024462154778],
                    [21.280202734184712, 48.19020458094252],
                    [21.28089677858094, 48.19018233614722],
                    [21.280956840121206, 48.19019568303119],
                    [21.28108363669523, 48.190275764215016]
                ]
            },
        },
    ]
};