$(document).on('pw.pageready', function() {
	$('#unregister-submit').click(function() {
		var form = $(this).closest('form');
		validateForm(form, ajax_controller + 'unregister', function(ret) {
			closeUnregModal();
		});
	});

	$('#unregister-cancel').click(function() {
		closeUnregModal();
	});

	var token = getUrlParameter('unregister');
	if (token) {
		$.post(ajax_controller + 'unregister_check', {regid: token}, function(res) {
			if (res.msg) {
				$('#unreg-form .form-row').addClass('hidden-row');
				$('#unreg-form .formfeedback').html(res.msg).show();
			}
			if (res.values) {
				for (var a in res.values) {
					$('.reg-data-value.' + a).html(res.values[a] ? res.values[a] : '-');
				}
				$('input[name="regid"]').val(res.values.id);
			}
			$('#unregister').closest('.jqmodal').data('modal').open();
		}, 'json');
	}

	function closeUnregModal() {
		$('#unregister').closest('.jqmodal').data('modal').close();
	}


});