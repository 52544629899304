var MapBox = function($element, options) {

	this.defaults = {
		'token' : '',
		'style' : '',
		'center' : [],
		'zoom' : 11,
		'markers' : [],
		'features' : null,
		'enable_drop' : false,
		'language' : {},
		'marker_base ' : '',
		'geolocate' : false
	};

	this.$element = $element;
	this.initialized = false;
	this.supported = false;

	this.options = $.extend({}, this.defaults, options);
 
 	if (this.options.token && typeof(mapboxgl) != 'undefined') {
		this.init();
	}

	return this;
};

MapBox.prototype = {

	init : function() {
		var _self = this;

		_self.supported = _self.webglSupported();
		if (!_self.supported) {
			_self.$element.append('<div class="webgl-error">' + _self.options.language.webglError + '</div>');
			return;
		}

		//set id of div
		_self.mapbox_id = _self.$element.attr('id');

		if(typeof _self.mapbox_id == 'undefined') {
			_self.mapbox_id = Math.random().toString(36).substring(7);
			_self.$element.attr('id', _self.mapbox_id);
		}

		//set access token
		mapboxgl.accessToken = _self.options.token;

		// _self.createMap();
	},

	webglSupported: function() {
	   try{
	    var canvas = document.createElement( 'canvas' ); 
	    return !! window.WebGLRenderingContext && ( 
	         canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) );
	   }catch( e ) { return false; } 
	},

	createMap : function(addUserLocation) {
		var _self = this;

		if(_self.initialized === true) {
			return false;
		}

		if (!_self.supported) {
			return false;
		}

		//create map
		_self.map = new mapboxgl.Map({
			container: _self.mapbox_id,
			style: _self.options.style,
			center: _self.options.center,
			zoom: _self.options.zoom,
			hash: false,
			attributionControl: false,
			scrollZoom: false,
			doubleClickZoom: true,
			touchZoomRotate: true
		});

		if (addUserLocation && navigator.geolocation) {
	        navigator.geolocation.getCurrentPosition(function(e) {
	        	if (e.coords) {
	        		var el = document.createElement('div');
					el.className = 'map-marker marker-self';

					var mapMarker = new mapboxgl.Marker(el, {offset: [0, -37]})
				 		.setLngLat([e.coords.longitude, e.coords.latitude])
				 		.addTo(_self.map);

				 	// _self.options.markers.push({
				 	// 	class: 'self',
				 	// 	lat: e.coords.latitude,
				 	// 	lng: e.coords.longitude
				 	// });

				 	// _self.fitBounds();
				}
	        });
		}

		//create markers

		_self.map.on('load', function() {
			_self.createMarkers();
			_self.createLayers();
		});

		_self.map.on('click', function (e) {
		    // console.log('[' + e.lngLat.lng + ', ' + e.lngLat.lat + ']');
		});

		_self.initialized = true;
	},

	createMarkers : function() {
		var _self = this;

		for(var i = 0; i < _self.options.markers.length; i++) {
			var marker = _self.options.markers[i];
			var popup_html = '';
			var popupVisible = false;
	
			var markerOptions = {};

			var el = document.createElement('div');
			el.className = 'map-marker marker-' + marker.class;

			switch (marker.class) {
				case 'red':
					popupVisible = true;
				case 'gold':

					var title = marker.title;
					if (marker.checkpoint) {
						title = '<div class="checkpoint-label">' + _self.options.language.checkpoint + '</div>' + title;
					}

					popup_html = '<div class="popup-cont ' + (marker.checkpoint ? 'checkpoint' : '') + '">';
					popup_html += '<div class="popup-title">' + title + '</div><div class="popup-address">' + marker.address + '</div>';
					popup_html += '</div>';

				break;
				case 'statue':
					popup_html = '';
					// markerOptions.offset = [-10, -17.5];
				break;
				case 'self':
					popup_html = '';
					markerOptions.offset = [0, -37];
				break;
			}

			if (marker.index) {
				var indexEl = document.createElement('span');
				indexEl.className = 'marker-index';
				indexEl.innerHTML = marker.index;

				el.appendChild(indexEl);
			}

			if (popup_html) {
				// var popup = new mapboxgl.Popup({
				// 	offset: [-22, 0],
				// 	anchor: 'right'
				// }).setHTML(popup_html);
				// mapMarker.setPopup(popup);

				var div = document.createElement('div');
				div.className = 'mapboxgl-popup mapboxgl-popup-anchor-right ' + (popupVisible ? 'popup-visible' : 'popup-hidden');
				div.dataset.role = 'popup';

				var content = document.createElement('div');
				content.className = 'mapboxgl-popup-content';

				var tip = document.createElement('div');
				tip.className = 'mapboxgl-popup-tip';

				var close = document.createElement('button');
				close.className = 'mapboxgl-popup-close-button';
				close.type = 'button';
				close.ariaLabel = 'Bezárás';
				close.dataset.role = 'close';

				content.append(close);
				content.innerHTML += popup_html;

				div.appendChild(tip);
				div.appendChild(content);
						
				el.appendChild(div);

				el.addEventListener('click', function(e) { 
					var indexClicked = e.target.className.indexOf('marker-index') > -1;

					if (e.target.dataset.role == 'close') {
						var clazz = e.target.parentElement.parentElement.className.replace('popup-visible', 'popup-hidden');
						e.target.parentElement.parentElement.className = clazz;
					}
					else if (e.target.className.indexOf('map-marker') > -1 || indexClicked) {
						var target = e.target;
						var popups = document.querySelectorAll('div.mapboxgl-popup');
						for (var a = 0; a < popups.length; a++) {
							var clazz = popups[a].className.replace('popup-visible', 'popup-hidden');
							popups[a].className = clazz;
						}

						if (indexClicked) {
							target = e.target.parentElement;
						}

						for (var a in target.children) {
							if (target.children[a].dataset.role == 'popup') {
								var clazz = target.children[a].className.replace('popup-hidden', 'popup-visible');
								target.children[a].className = clazz;
								break;
							}
						}
					}
				}); 
			}

			var mapMarker = new mapboxgl.Marker(el, markerOptions)
			    .setLngLat([marker.lng, marker.lat]);

			mapMarker.addTo(_self.map);

		}

		_self.fitBounds();
	},

	createLayers: function() {
		var _self = this;

		if (_self.options.features) {

			_self.map.addSource('features', {
				type: 'geojson',
				data: _self.options.features
			});

			// _self.map.addLayer({
			// 	id: 'placc', //ID kötelező
			// 	type: 'fill',
			// 	source: 'features',
			// 	paint: _self.options.features.properties.styles.polygon,
			// 	filter: ["==", "$type", "Polygon"]
			// });
			
			_self.map.addLayer({
				id: 'erremegyunk', //ID kötelező
				type: 'line',
				source: 'features',
				layout: {
                    "line-join": "round",
                    "line-cap": "round"
                },
				paint: _self.options.features.properties.styles.linestring,
				filter: ["==", "$type", "LineString"]
			});
		}
	},

	fitBounds: function() {
		var _self = this;
		var bounds = new mapboxgl.LngLatBounds();

		_self.options.markers.forEach(function(marker) {
		    bounds.extend([marker.lng, marker.lat]);
		});

		_self.map.fitBounds(bounds, {padding: 100, easing: function(i) {return 1}});
	}

};
