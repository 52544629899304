var opened_modal = false;

var $jqmodal_overlay = $('<div/>', {
	'class' : 'jqmodal-overlay'
});

$(document).ready(function() {
	$('body').append($jqmodal_overlay);
});

var jQModal = function($element, options) {
	var default_options = {
		beforeOpen: function() {},
        afterOpen: function() {},
        beforeClose: function() {},
        afterClose: function() {},
        openDelay: 0
	};

	this.$element = $element;
	this.options = $.extend({}, default_options, options);

	this.$body = $('body');

	this.initialized = false;
	this.event_str = 'jqmodal' + Math.floor(Math.random() * 100) + 1;

	this.init();
};

jQModal.prototype.init = function() {
	var _self  = this;

	if(_self.initialized !== false)
		return false;

	_self.scrollbar_width = getScrollBarWidth();

	//create content
	createModal();

	//create close button
	createCloseButton();

	//find all anchor to this popup
	_self.$links = $('[data-modal-link="#' + _self.$element.attr('id') + '"]');
	_self.$links.on('click.' + _self.event_str, createClickEvent);



	//set base url
	setBaseUrl();


	/** create click event **/
	function createClickEvent(e) {
		e.preventDefault();
		if ($(this).hasClass('disabled')) return;
		_self.open();
	}

	function setBaseUrl() {
		// var url = window.location.href;
		// var index = url.indexOf('#');
		// var url_hash = null;

		// if(index === -1) {
		// 	_self.base_url = url;
		// }
		// else {
		// 	_self.base_url = url.substring(0, index);

		// 	url_hash = url.substring(index);
		// }

		 _self.url = '#' + _self.$element.attr('id');

		//open the necessary popup
		// if(url_hash === _self.url)
		// 	_self.open();

	}

	/** create modal content **/
	function createModal() {
		_self.$modal = $('<div/>', {
			'class' : 'jqmodal'
		});

		//append content to modal
		/*var $orig_element = _self.$element;
		_self.$element = $orig_element.clone();
		$orig_element.remove();*/

		_self.$modal.append(
			_self.$element.addClass('modal-content')
		);

		//append modal to body
		_self.$body.append( _self.$modal );
		_self.$modal.data('modal', _self);
	}

	/** create close button **/
	function createCloseButton() {
		_self.$close = $('<a/>', {
			'class' : 'jqmodal-close'
		});

		_self.$element.append( _self.$close );
	}

	/** get scrollbar width **/
	function getScrollBarWidth () {
		var inner = document.createElement('p');
		inner.style.width = "100%";
		inner.style.height = "200px";

		var outer = document.createElement('div');
		outer.style.position = "absolute";
		outer.style.top = "0px";
		outer.style.left = "0px";
		outer.style.visibility = "hidden";
		outer.style.width = "200px";
		outer.style.height = "150px";
		outer.style.overflow = "hidden";
		outer.appendChild (inner);

		document.body.appendChild (outer);
		var w1 = inner.offsetWidth;
		outer.style.overflow = 'scroll';
		var w2 = inner.offsetWidth;
		if (w1 == w2) w2 = outer.clientWidth;

		document.body.removeChild (outer);

		return (w1 - w2);
	};

	
};

jQModal.prototype.callback = function(callback) {
	var _self = this;
	
	var type = typeof callback;

	if(type === 'function') {
		callback.call( _self.$element );
	}
	else if(type === 'string') {
		var fn = window[callback];
		if(typeof fn === 'function') {
		    fn( _self.$element );
		}
	}

};

jQModal.prototype.setModalPosition = function() {
	var _self = this;

	var vph = $(window).height();
	var elh = _self.$element.outerHeight() + parseInt(_self.$element.css('margin-top')) + parseInt(_self.$element.css('margin-bottom'));

	if(elh >= vph)
		_self.$element.css('top', 'auto');
	else {
		_self.$element.css('top', (vph - elh) / 2);
	}
};

jQModal.prototype.open = function(type) {
	var _self = this;

	if(opened_modal !== false)
		return false;
	
	if (type) {
		_self.url = type;
	}

	opened_modal = _self.url;

	
	_self.$modal.css({width: '100%', position: 'fixed'});


	_self.setModalPosition();

	setTimeout(function() {
		_self.options.openDelay = 0;

		_self.callback( _self.options.beforeOpen );

		_self.body_right_padding = _self.$body.css('padding-right');

		_self.$body
					.addClass('overlay-open')
					.css('padding-right', _self.scrollbar_width);
		_self.$modal.addClass('opened');


		/** init events **/
		_self.$close.on('click.' + _self.event_str, function() {
			_self.close();
		});
		_self.$modal.on('click.' + _self.event_str, function(e) {
			if($(e.target).is(_self.$modal)) {
				_self.close();
			}
		});

		_self.callback( _self.options.afterOpen );

	}, _self.options.openDelay);

	// history.pushState( null, null, _self.base_url +  _self.url);
};

jQModal.prototype.close = function() {
	var _self = this;

	_self.callback( _self.options.beforeClose );

	_self.$body
				.removeClass('overlay-open')
				.css('padding-right', _self.body_right_padding);
	_self.$modal.removeClass('opened');

	_self.$modal.css({width: 0, position: 'absolute'});

	/** remove events **/
	_self.$close.off('click.' + _self.event_str);
	_self.$modal.off('click.' + _self.event_str);

	_self.callback( _self.options.afterClose );

	// history.pushState( null, null, _self.base_url);

	opened_modal = false;
};



/*** open jq modal by attributes ***/
$('.modal-content').each(function(ind, el) {
	new jQModal($(this), {
        beforeOpen: $(this).data('before-open'),
        afterOpen: $(this).data('after-open'),
        beforeClose: $(this).data('before-close'),
        afterClose: $(this).data('after-close'),
        openDelay: $(this).data('open-delay')
    });
});
