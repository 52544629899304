
var menuAnimating = false;
var mapOptions = null;
var google_map_loaded = false;

$(document).ready(function() {

	/* MOBILE */

	__buildMobileMenu();
	__doresize();


	$('.overlay-open').click(function(e) {
		e.preventDefault();
		e.stopPropagation();

		var target = $(this).data('target');
		$(target).fadeIn(300);
	});

	$('.mobile-menu-closer').click(function(e) {
		e.preventDefault();
		e.stopPropagation();
		var parent = $(this).parent();

		parent.fadeOut(300, function() {
		});
	});


	$('.header li.top > a').click(function(e) {
		e.stopPropagation();

		if (menuAnimating) return;
		menuAnimating = true;
		// console.log('fuss');
		var ul = $('.submenu');
		var menuitem = $(this).parent();

		if (!ul.hasClass('open')) {
			menuitem.addClass('open');

			setTimeout(function() {
				ul.addClass('open');
				setTimeout(function() {
					menuAnimating = false;
				}, 300);
			}, 300);
		}
		else {
			ul.removeClass('open');
			setTimeout(function() {
				menuitem.removeClass('open');
				menuAnimating = false;
			}, 300);
		}
	});

	$('.header .submenu').click(function(e) {
		e.stopPropagation();
	});

	$('.header .submenu li a').click(function(e) {
		e.preventDefault();
		var link = $(this).attr('href');
		$('li.toplevel.open > a').click();
		setTimeout(function() {
			window.location = link;
		}, 500);
	});

	// $('body').click(__closeAllToggle);


	// $('#gototop').click(function(e) {
	// 	var scroll = $(document).scrollTop();
	// 	$('html, body').animate({scrollTop: 0}, Math.round(scroll / 100) * 50);
	// });

	//create js hook links
	$('a[data-link]').on('click', function() {
		var $linked_item = $('#' + $(this).data('link'));
		if($linked_item.length > 0) {
			$('html, body').animate({
		        scrollTop: $linked_item.offset().top
		    }, 500);
		}
	});

	$('.mailtolink').click(function(e) {
		var th = $(this);
		if (th.attr('href') == 'javascript:void(0)') {
			// e.preventDefault();
			var hidden = th.children('.emailhidden');
			th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
		}
	});

    var ua = window.navigator.userAgent;
    var nav_protocol = 'https://maps.google.com/?daddr=';
    if( ua.indexOf('Mac') > 0){
        nav_protocol = 'https://maps.apple.com/?daddr=';
    }

    $('.nav-button').each(function(){
        $(this).attr({
        	href: nav_protocol+$(this).data("address"),
        	target: '_blank'
    	});
    });

    $('.tooltip').each(function(ind, el) {
    	var th = $(el);
    	createTooltip(th);
    });

	__createFormInputs();


	$(document).on('pw.pageready', function() {

		new EffectMachinator({
    		selector: 'section.has-effect',
	        minWidth: 0,
	        delayOffset: 100
    	});

    	$('.equal-heights').each(function(ind, el) {
    		new EqualHeights($(el));
    	});

	});

	$('[data-purpose="purchase-link"]').click(function(){
		redirectToPurchasePage($(this));
	});
});

$(window).load(function() {

	setTimeout(function() {

		$('body').removeClass('loading');

		var resizeHandler = debounce(function() {
			__doresize();
	    }, 250);

		$(window).resize(resizeHandler);


		__doresize();

		$(document).trigger('pw.pageready');
		
	}, 100);

});

function __doresize() {
}

function __closeAllToggle() {
	if ($('#mobile-menu').hasClass('opened'))
		$('#mobile-toggle-cont').click().removeClass('tcon-transform');

	// $('li.top.open > a').click();

	// menuAnimating = false;
}


function __buildMobileMenu() {
	var menu = $('#mobile-menu #menuitems');

	$('.page-header li.toplevel').each(function(ind, el) {
		var th = $(el);
		var li = $('<li />');
		li.append(th.children().first().clone());
		li.click(function() {
			$('.mobile-menu-closer').click();
		});
		var submenus = th.find('li');

		if (submenus.length) {
			var sub1 = $('<ul class="submenu" />');
			li.addClass('has-child');
			sub1.appendTo(li);
			submenus.each(function(ind, el1) {
				var s1 = $(el1);
				var li = s1.clone();
				sub1.append(li);
				li.addClass('level1');
			});
		}

		menu.append(li);

	});

}

function __createFormInputs() {
	$('.mdl-js-textfield').each(function(ind, el) {
		var th = $(el);
		var input = th.children();
		var label = th.data('label');
		if (label == undefined) {
			label = input.attr('placeholder');
			input.attr('placeholder', null);
		}
		var id = input.attr('id');
		if (id == undefined) {
			id = input.attr('name');
			input.attr('id', id);
		}
		var classes = 'mdl-textfield';
		th.addClass(classes);
		input.addClass('mdl-textfield__input');

		if (label != undefined) {
			th.addClass('mdl-textfield--floating-label');
			th.append('<label class="mdl-textfield__label" for="' + id + '">' + label + '</label>');
		}

	});

	$('.mdl-checkbox').each(function(ind, el) {
		var th = $(el);
		var input = th.children();
		var label = th.data('label');
		if (label == undefined) {
			label = input.attr('value');
		}
		var id = input.attr('id');
		if (id == undefined) {
			id = input.attr('name') + '_' + ind;
			input.attr('id', id);
		}
		th.attr('for', id);
		var classes = 'mdl-js-checkbox mdl-js-ripple-effect';
		th.addClass(classes);
		input.addClass('mdl-checkbox__input');

		th.append('<span class="mdl-checkbox__label">' + label + '</span>');

	});

	$('.mdl-radio').each(function(ind, el) {
		var th = $(el);
		var input = th.children();
		var label = th.data('label');
		var error = th.data('error');
		if (label == undefined) {
			label = input.attr('value');
		}
		var id = input.attr('id');
		if (id == undefined) {
			id = input.attr('name') + '_' + ind;
			input.attr('id', id);
		}
		th.attr('for', id);
		var classes = 'mdl-js-radio mdl-js-ripple-effect';
		th.addClass(classes);
		input.addClass('mdl-radio__button');

		th.append('<span class="mdl-radio__label">' + label + '</span>');

		if (error) {
			th.append('<span class="mdl-textfield__error">' + error + '</span>');
		}

	});

	$('.custom-select:not(".with-button")').each(function(ind, el) {
		var th = $(el);
		var options = th.children('select');
		var id = options.attr('name');
		var label = th.children('.mdl-js-textfield');
		var defaultValue = '';

		var ul = th.children('ul');
		options.children().each(function(ind2, el2) {
			var th2 = $(el2);
			if (!ind2) defaultValue = th2.text();
			ul.append('<li class="mdl-menu__item" data-value="' + th2.attr('value') + '">' + th2.text() + '</li>');
		});
		ul.attr('for', id).addClass('mdl-menu--bottom-left mdl-js-menu mdl-js-ripple-effect');

		label.click(function(e) {
			e.preventDefault();
			e.stopPropagation();
			label.addClass('is-dirty');
			th.toggleClass('active');
			th.find('.mdl-menu__container').toggleClass('is-visible');
		});

		th.find('li').click(function() {
			var sel = $(this).text();
			label.removeClass('is-invalid').children('input').val(sel);
			options.val(sel);
			if (!options.val()) label.removeClass('is-dirty');
		});

		label.find('input').val(defaultValue);

	});

	$('.custom-select.with-button').each(function(ind, el) {
		var th = $(el);
		var options = th.children('select');
		var id = options.attr('name');
		var label = th.data('label');
		var url = th.data('url');

		var ul = th.children('ul');
		options.children().each(function(ind2, el2) {
			var th2 = $(el2);
			ul.append('<li class="mdl-menu__item" data-value="' + th2.attr('value') + '">' + th2.text() + '</li>');
		});
		ul.attr('for', id).addClass('mdl-menu--bottom-left mdl-js-menu mdl-js-ripple-effect');
		th.append('<a class="button orange-button" href="javascript:void(0)" id="' + id + '">' + label + '<span class="sprite mainSprite-whiteSelectArrow"></span></a>');

		th.find('.button').click(function(e) {
			e.preventDefault();
			e.stopPropagation();
			$(this).toggleClass('clicked');
		});

		th.find('li').click(function() {
			var sel = $(this).data('value');
			options.val(sel);

			if (url != undefined) {
				window.location = url + sel;
			}
		});

	});

	refreshInputs();

}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function refreshInputs() {
	componentHandler.upgradeAllRegistered();
}


function validateForm( $form, url, callback ) {
    $form.find('.is-invalid').removeClass('is-invalid');
	$form.find('.is-invalid-row').removeClass('is-invalid-row');
	$form.find('.error-text').remove();
	$form.find('.orange-button').addClass('active');

	$form.find('.form-row').addClass('hidden-row');
	var feedback = $form.find('.formfeedback');
	var errors = $form.find('.formerror');

	feedback.html(feedback.data('default-text')).fadeIn(500);

	$.post(url, $form.serialize(), function(ret) {
		if (!ret.success) {
			feedback.hide();
			if (errors.length) {
				var delay = ret.msg.length * 150;
				errors.html(ret.msg).removeClass('hidden').addClass('shown');
				setTimeout(function() {
					errors.removeClass('shown');
					setTimeout(function() {
						errors.addClass('hidden');
					}, 300);
				}, delay);
			}

			$form.find('.orange-button').removeClass('active');
			if (ret.empties != undefined) {
				for (var i in ret.empties) {
					var parent = $('[name="' + ret.empties[i] + '"]').parent();
					parent.addClass('is-invalid');

					if($.inArray($('[name="' + ret.empties[i] + '"]').attr('type'), ['radio', 'checkbox']) !== -1) {
						var $parent = $('[name="' + ret.empties[i] + '"]').closest('.form-row');
						$parent.addClass('is-invalid-row');

						$parent.find('.form-row-title').append($('<span/>', {
							'html' : $parent.data('invalid-text'),
							'class' : 'error-text'
						}));
					}
				}
			}

			if (ret.errors != undefined) {
				for (var i in ret.errors) {
					var parent = $('[name="' + ret.errors[i] + '"]').parent();
					parent.addClass('is-invalid');

					if (parent.data('invalid-text') != undefined) {
						parent.after($('<span/>', {
							'html' : parent.data('invalid-text'),
							'class' : 'error-text'
						}));
					}
				}
				$form.closest('.jqmodal').animate({scrollTop: $('[name="' + ret.errors[0] + '"]').closest('.form-row').scrollTop() }, 500);
			}

			$form.find('.form-row').removeClass('hidden-row');
		}
		else {
			$form.get(0).reset();
			$('.is-dirty').removeClass('is-dirty');
			$('.is-focused').removeClass('is-focused');
			$form.find('.is-checked').each(function(ind, el) {
				var th = $(el);
				th.find('input').prop('checked', false);
				th.removeClass('is-checked');
			});
			$form.find('.form-collapsable').slideUp();

			if (typeof callback == 'function') {
				callback(ret);
			}

			if (ret.redirect) {
				setTimeout(function() {
					window.location = ret.redirect;
				}, 1300)
			}
			else {

				if (ret.msg) {
					feedback.hide().html(ret.msg).fadeIn(500);
					setTimeout(function() {
						feedback.fadeOut(function() {
							var modal = $form.closest('.jqmodal.opened').data('modal');
							if (modal)
								modal.close();
							$form.find('.form-row').removeClass('hidden-row');
						});
					}, 5000);
				}
			}

		}
	}, 'json');
}

var dictionary = {};
function translate(elements, callback){
   
    if(typeof callback === 'undefined')
    {
        callback = function() {};
    }

    if (typeof elements == 'object') {

    	var req = [];
    	for (var a in elements) {
    		if (typeof dictionary[elements[a]] == 'undefined') {
    			req.push(elements[a]);
    		}
    	}

    	if (req.length) {
    		$.post(ajax_controller + '/translate', {items: req}, function(rData) {

	        	if (rData) {
		        	for (var i in rData) { 
		            	dictionary[i] = rData[i];
		        	}
	        	}
	            
	            callback.call(undefined);

	        }, 'json');
    	}
    	else {
			callback.call(undefined);
    	}
    }

}

function createTooltip(th) {
	var options = {
		maxWidth: '70%',
		tooltipHover: true,
		titleBackground: '',
		background: '',
		delay: 0,
		speed: 200
	};

	if (th.data('title')) {
		options.useTitle = true;
		options.titleContent = th.data('title');
	}

	if (th.data('position')) {
		options.position = th.data('position');
	}

	if (th.data('width')) {
		options.width = th.data('width');
	}

	var helper = th.parent().find('.tipso');
	if (helper.length) {
		options.content = helper.html();
	}

	th.tipso(options);
}


function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? false : sParameterName[1];
        }
    }
}

function removeQueryString() {
	history.pushState(null, null, window.location.href.split("?")[0]);
}

function handleEffect(index) {
	switch (index) {
		case 'mapbox':

			var map = $('#home-map');
			if (map.length) {
				mapboxObject = new MapBox(map, mapboxOptions);
				mapboxObject.createMap();
			}
		break;
	}
}


function initPopupMap() {
	var map = $('#popup-map');
	if (map.length) {
		// if (!map.data('map-inited')) {
			mapboxObject = new MapBox(map, mapboxOptions);
			mapboxObject.createMap(true);
			// map.data('map-inited', true);
		// }
		// else {
		// 	mapboxObject.fitBounds();
		// }
	}
}

function redirectToPurchasePage(button)
{
	var link = button.data('purchase-link');
	if (link.trim() !== '' && !button.hasClass('clicked')) {
		button.addClass('clicked');
		var type = button.data('type');
		if (type) {
			if (typeof gtag === 'function') {
				gtag('event', 'click_button', {
					'event_category': 'click',
					'event_label': type
				});
			} else if (typeof ga === 'function') {
				ga('send', 'event', 'click', type);
			}
		}
		
		setTimeout(function(){
			document.location.href = link;
			button.attr('href', link);
		}, 100);
	}
}
