var EffectMachinator = function(options){

    this.defaults = {
        selector: 'section.has-effect',
        delayOffset: 0,
        minWidth: 0,
    };

    this.options = jQuery.extend({}, this.defaults, options);

    this.effectData = {};
    var _self = this;

    var items = $(this.options.selector);
    if (items.length) {
        items.each(function(ind, el) {
            var th = $(el);
            var index = th.attr('data-key');
            if (index) {
                _self.effectData[index] = {
                    top: 0,
                    bottom: 0,
                };
            }
        });
    }

    this.enabled = Object.keys(this.effectData).length;
    this.scrolling = false;

    if (this.enabled) this.init();
};

EffectMachinator.prototype = {
    defaults: {},

    init: function ()
    {
        var _self = this;

        if (this.options.minWidth && $(window).width() < this.options.minWidth) {
            for (var a in this.effectData) {
                var section = $('[data-key="' + a + '"]');
                section.find('svg.delayed').remove();
                section.find('.svg-alternate').show();
                this.showEffect(section);
            }

            return;
        }

        $(window).resize(function() {
            _self.update();

        }).scroll(function() {
            _self.handleScroll();
        });

        _self.update();
        _self.handleScroll();
    },

    update: function() {
        var _self = this;

        if (this.options.minWidth) {
            this.enabled = $(window).width() >= this.options.minWidth;
        }

        if (!this.enabled) return;

        for (var a in this.effectData) {
            var th = $('[data-key="' + a + '"]');
            this.effectData[a].top = th.position().top;
            this.effectData[a].bottom = th.position().top + th.outerHeight(true);
            this.effectData[a].small = th.outerHeight(true) < $(window).height();
        }
    },

    handleScroll: function() {
        var _self = this;

        if (!this.enabled || this.scrolling) return;
    
        this.scrolling = true;
        
        var amount = $(document).scrollTop();

        for (var a in this.effectData) {
            var section = $('[data-key="' + a + '"]');
            if (amount >= this.effectData[a].top + this.options.delayOffset && amount < this.effectData[a].bottom) {
                
                this.showEffect(section);

                delete this.effectData[a];
                if (!Object.keys(this.effectData).length) this.enabled = false;   
            }
        }

        this.scrolling = false;
    },

    showEffect: function (section) {

        var disabledSvg = section.data('disable-svg');

        section.find('svg.delayed').each(function(ind, el) {
            if (disabledSvg == el.id) {
                $(el).find('path').css('visibility', 'visible');
            }
            else {
                new SvgAnimator(el);
            }
        });

        section.addClass('effect-shown');

        var animationItems = section.find('[data-role="effect-delayed-item"]');

        if (animationItems.length) {
            animationItems.each(function(ind, el) {
                var th = $(el);
                setTimeout(function() {
                    th.addClass('animated');
                }, ind * 100);
            });
        }

        if (typeof handleEffect == 'function') {
            handleEffect(section.data('key'));
        }

    }
};