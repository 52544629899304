$(document).ready(function() {

	var token = getUrlParameter('retry-payment');
	if (token) {

		var form = $('.payment-form');
		var id = $('input[name="submission_id"]').val();

		if (!id) {
			form.find('.form-row').addClass('hidden-row');
			form.find('.formfeedback').show();
		}
		else {	

			$('.payment-submit').click(function() {
				validateForm(form, ajax_controller + 'retry_payment', function(ret) {
					if (ret.success) {
						if (typeof window.fbq === 'function') {
							fbq('track', 'RetryPurchase', {
								value: ret.price,
								currency: ret.currency
							})
						}
						closePaymentModal();
					}
				});
			});
		}

		form.closest('.jqmodal').data('modal').open();
	}

	function closePaymentModal() {
		$('.payment-form').closest('.jqmodal').data('modal').close();
	}
});
