$(document).ready(function() {
	
	//new EqualHeights('.kiallito-items', {contentSelector: '.kiallito-desc'});
	//new DelayedFade('.kiallito-items', {itemSelector: '.kiallito'});

	var fixedButtonTrigger = $('[data-role="fixed-button-trigger"]');

	var video = $('.landing-video');
	if (video.length) {

		var videoAPI = video.get(0);

		 $(window).on('load scroll', function () {
	        var scrolled = $(this).scrollTop();

			if (scrolled > $(window).height()) {
	        	videoAPI.pause();
	        }
	        else if (videoAPI.paused) {
	        	videoAPI.play();
	        }
	    });
	}

	$('.selector .selector-item').click(function() {
		$(this).parents('.selector').children('.selector-item').removeClass('active');
		$(this).addClass('active');

		$($(this).data('tab')).show().siblings().hide();
	});

	$('.selector .selector-item:first-of-type').click();

	$('.landing-content .register-button').click(function () {
		if (typeof window.fbq === 'function') {
			fbq('track', 'landing_regisztracio_click')
		}
	})

	$('.landing-content .kiallitok-button').click(function () {
		if (typeof window.fbq === 'function') {
			fbq('track', 'landing_kiallitok_click')
		}
	})

	$('.article-section .tovabbi-info-button').click(function () {
		if (typeof window.fbq === 'function') {
			fbq('track', 'tovabbi_informaciok_click')
		}
	})

	$('.ticket.vip .register-button').click(function () {
		if (typeof window.fbq === 'function') {
			fbq('track', 'vip_reg_click')
		}
	})

	$('.ticket.3day .register-button').click(function () {
		if (typeof window.fbq === 'function') {
			fbq('track', '3_napos_reg_click')
		}
	})

	$('.ticket.1day .register-button').click(function () {
		if (typeof window.fbq === 'function') {
			fbq('track', '1_napos_reg_click')
		}
	})

	$('.vote-button').click(function() {
		if ($(this).hasClass('disabled')) return

		
		var restaurant = $('select[name=restaurant]').val();
		var name = $('input[name=name]').val();
		var email = $('input[name=email]').val();
		var privacy = $('#privacy').prop('checked');

		console.log(privacy);

		$('.formerror').addClass('hidden').removeClass('shown')

		$('.vote-button').addClass('disabled')

		$.ajax({
			url: '/ajax/szavazas',
			method: 'POST',
			dataType: 'json',
			data: {
				restaurant: restaurant,
				name: name,
				email: email,
				privacy: privacy,
			}
		})
			.then(function (resp) {
				$('.vote-button').removeClass('disabled')
				if (resp.error) {
					$('.formerror').text(resp.error).removeClass('hidden').addClass('shown')
					resp.missing_fields.forEach(function (field) {
						$('[name='+field+']').parent().addClass('is-invalid')
					})
				} else {
					if (resp.redirect) {
						window.location = resp.redirect
					}
				}
			}, function (err) {
				$('.vote-button').removeClass('disabled')
			})
	})
});
